import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { R, C, C4, C5, C6, Text, SectionDivider, Note, ListItem, TeamItem, Badge, IconRightArrow, EmptyTeams, CreatedBy } from "@fundrecs/ui-library";
import { teamsStore, rolesStore, usersStore, meStore } from "../../../store/Store";
import { fusionApi } from "../../../api";
import { ROUTES } from "../../../utils/Enums";
import { EditableText } from "../components/editable/EditableText";
import { EditAdminAccess } from "../manageUsers/EditAdminAccess";
import { UsersContext } from "../manageUsers/ManageUsers";
import { VALIDATION_MESSAGES } from "../util/validation";

const PersonalDetails = observer(() => {
  const { selectedUser, setSelectedUser } = useContext(UsersContext);
  const { name, email, createdBy, createdOn } = selectedUser;
  const me = meStore.getMe();

  const submitUser = async (props) => {
    const { name: newName = name } = { ...props };

    let requestObject = JSON.parse(JSON.stringify(selectedUser));
    requestObject.name = newName;
    const isThisMe = me.id === requestObject.id;
    const toasts = {
      success: `User's full name successfully changed`,
      error: `Unable to change user's full name! Please try again`,
    };
    const { data, success } = isThisMe ? await meStore.update(requestObject, toasts) : await usersStore.update(requestObject, toasts);
    if (success) {
      setSelectedUser(data);
      usersStore.replaceUser(data);
      if (isThisMe) {
        meStore.setMe(data);
      }
    }
  };

  return (
    <>
      <R props={"pb-12"}>
        <EditableText
          name="userName"
          label="Full name"
          value={name}
          onSave={(value) => {
            submitUser({ name: value });
          }}
          warningText={VALIDATION_MESSAGES.REQUIRED_FIELD}
        />
      </R>
      <SectionDivider />
      <R props={"pt-12 pb-12"}>
        <C4>
          <Text variant="tertiary" size="sm" weight="regular" element="div">
            Email address
          </Text>
        </C4>
        <C6 props="text-break">
          <Text variant="primary" size="sm" weight="medium" element="div">
            {email}
          </Text>
        </C6>
      </R>
      <SectionDivider />
      <R props={"pt-12"}>
        <C4>
          <Text size="sm" variant="tertiary" weight="regular">
            Created by
          </Text>
        </C4>
        <C props="pl-0">
          <CreatedBy user={createdBy} date={createdOn} />
        </C>
      </R>
    </>
  );
});

const AdminAccess = observer(() => {
  const { selectedUser, setSelectedUser } = useContext(UsersContext);

  // Only Super Admin can edit User Admin Access
  const canEdit = meStore.isSuperAdmin();

  //Admin Roles are on TeamId=1
  const teamId = 1;

  let adminRoles = [];

  const rolesIds = selectedUser.adminRolesActions ? selectedUser.adminRolesActions.roles : [];

  if (rolesIds !== undefined) {
    rolesIds.map((role) => {
      adminRoles.push(rolesStore.getRoleById(role.id));
    });
  }

  const editAdminRoles = async ({ userId, newRoles }) => {
    // User do not have admin access yet, add to tenant team before adding roles
    if (adminRoles.length === 0) {
      await addUsersToTenantTeam();
    }

    await usersStore.resetRolesInATeam({ userId: userId, teamId: teamId, newRoles: newRoles });

    // User updated to not have admin access
    if (newRoles.length === 0) {
      await removeUsersFromTenantTeam();
    }
    const updatedUser = await usersStore.requestUser(userId);
    usersStore.replaceUser(updatedUser);
    setSelectedUser(updatedUser);
  };

  const addUsersToTenantTeam = async () => {
    await fusionApi.teams.api.addUserToTeam({ teamId: teamId, userId: selectedUser.id });
  };

  const removeUsersFromTenantTeam = async () => {
    await fusionApi.teams.api.removeUserFromTeam({ teamId: teamId, userId: selectedUser.id });
  };

  return (
    <R>
      <EditAdminAccess
        canEdit={canEdit}
        userRoles={adminRoles}
        onSave={(value) => {
          editAdminRoles({ userId: selectedUser.id, newRoles: value });
        }}
      />
    </R>
  );
});

const UserTeams = observer(() => {
  const { selectedUser } = useContext(UsersContext);
  const { teamRolesActions, superAdmin: isSuperAdmin } = selectedUser;

  const TeamDetails = () => {
    const history = useHistory();
    const allTeams = teamsStore.getTeams();
    const allRoles = rolesStore.getRoles();

    const getTeam = (id) => {
      return allTeams.find((t) => t.id === id);
    };

    return (
      <>
        {teamRolesActions
          .slice()
          .sort((a, b) => (getTeam(a.team.id).name.toLowerCase() > getTeam(b.team.id).name.toLowerCase() ? 1 : -1))
          .map((row, index) => {
            // Team name and members
            const team = allTeams.find((t) => t.id === row.team.id);
            const { id: teamId, name, users = [] } = team || {};

            // User roles
            const userRoles = teamRolesActions.find((t) => t.team.id === row.team.id).roles;

            return (
              <div key={index}>
                <R key={index}>
                  <C5>
                    <ListItem key={index}>
                      <TeamItem avatar={name} body={`${users.length} members`} header={name} />
                    </ListItem>
                  </C5>
                  <C5>
                    {userRoles.map((teamRole, index) => (
                      <Badge key={index} text={allRoles.find((role) => role.id === teamRole.id).name.toLowerCase()} />
                    ))}
                  </C5>
                  <C props="text-end">
                    <span
                      className="d-flex justify-content-end hand-pointer light-subtitle1"
                      onClick={() => {
                        history.push(`${ROUTES.TEAMS}/${teamId}`);
                      }}
                    >
                      <IconRightArrow className={`btn-lg-svg`} />
                    </span>
                  </C>
                </R>
                {index < teamRolesActions.length - 1 ? <SectionDivider props="pl-0 pr-0 pt-12 pb-12" /> : ""}
              </div>
            );
          })}
      </>
    );
  };

  const NoTeams = () => {
    return (
      <>
        <div className="mr-16 ml-16 negative-margin-12">
          <EmptyTeams style={{ height: "35px", width: "40px", marginRight: "12px" }} />
          <Text variant="tertiary" size="sm" weight="regular">
            There are currently no teams assigned to this user
          </Text>
        </div>
      </>
    );
  };

  const SuperAdminTeams = () => {
    return (
      <div className="mr-16 ml-16 negative-margin-12">
        <Note>
          This Super admin user has access to all teams and their content in Fusion by default, including new teams that will be added to Fusion in the future.
        </Note>
      </div>
    );
  };

  return isSuperAdmin ? <SuperAdminTeams /> : teamRolesActions.length > 0 ? <TeamDetails /> : <NoTeams />;
});

export { PersonalDetails, AdminAccess, UserTeams };
