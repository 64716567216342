import { makeAutoObservable, toJS } from "mobx";
import { fusionApi } from "../api";
import { usersStore, uiStore } from "../store/Store";
import { TEAM_SETTINGS_TYPES } from "../utils/Enums";

const { teams } = fusionApi;

class TeamsStore {
  adminTeam = {};
  teams = [];
  teamName = "";

  constructor() {
    makeAutoObservable(this);
  }

  setTeams = (teams) => {
    teams = teams.filter((team) => {
      if (team.admin) {
        this.adminTeam = team;
      } else {
        return team;
      }
    });
    this.teams = teams;
  };

  getTeams = () => {
    return toJS(this.teams);
  };

  getAdminTeam = () => {
    return toJS(this.adminTeam);
  };

  getTeamById(teamId) {
    let team = null;
    for (let i = 0, n = this.teams.length; i < n; i++) {
      if (this.teams[i]["id"] == teamId) {
        team = this.teams[i];
      }
    }
    return toJS(team);
  }

  setTeamName(value) {
    this.teamName = value;
  }

  getTeamName() {
    return this.teamName;
  }

  ping = () => {
    console.log("You have pinged the team store");
  };

  getWorkflow4EyesSetting = (team) => {
    return team.settings.find((setting) => setting.settingFor === TEAM_SETTINGS_TYPES.WORKFLOW_4_EYES);
  };

  getTemplate4EyesSetting = (team) => {
    return team.settings.find((setting) => setting.settingFor === TEAM_SETTINGS_TYPES.TEMPLATE_4_EYES);
  };

  getTeamMembers = (team) => {
    const usersDetails = [];

    team.users.forEach((userId) => {
      const userDetails = usersStore.getUserById(userId.id);
      const userRolesCreatedDate = userDetails.teamRolesActions.find((teamRolesActions) => teamRolesActions.team.id === team.id);
      let user = { ...userDetails };
      user.roles = userRolesCreatedDate === undefined ? [] : userRolesCreatedDate.roles;
      user.createdOnTeam = userRolesCreatedDate === undefined ? "" : userRolesCreatedDate.createdOn;

      usersDetails.push(user);
    });
    return usersDetails;
  };

  getTeamMemberCount(teamId) {
    const team = this.getTeamById(teamId);
    return team.users.length;
  }

  getNewest = () => {
    return this.getTeams()
      .slice()
      .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
      .slice(-1)[0];
  };

  removeTeam = (team) => {
    const filteredTeams = this.teams.filter((tm) => tm.id !== team.id);
    this.setTeams(filteredTeams);
  };

  replaceTeam = (team) => {
    let teams = this.getTeams();
    const index = teams.findIndex((index) => index.id === team.id);
    teams[index] = team;
    this.setTeams(teams);
  };

  requestTeams = async () => {
    const response = await teams.requests.list();
    const { data, status } = response;
    if (status === 200) {
      return data;
    } else {
      uiStore.addNotification("error", `${data}`);
      return [];
    }
  };

  requestTeam = async (teamId) => {
    const response = await teams.requests.read(teamId);
    const { data } = response;
    return data;
  };

  verifyNameIsAvailable = async (value) => {
    return await teams.requests
      .verifyNameIsAvailable(value)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to Verify if team name is available! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        return uiStore.addNotification("error", "Unable to Verify if team name is available! Please try again");
      });
  };

  /**
   * This method sends a request to the create team api. It's param is a
   * request object resembling the team you want to create.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   * @param  request
   * @returns true/false if request succeeded/failed
   */
  createTeam = async (request) => {
    return teams.api
      .create(request)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `Team "${request.name}" successfully added!`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to add "${request.name}" team! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        return uiStore.addNotification("error", `Unable to add "${request.name}" team! Please try again`);
      });
  };

  /**
   * This method sends a request to the delete team api. It's param is a
   * team id you want to delete.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   * @param  request
   * @returns true/false if request succeeded/failed
   */
  deleteTeam = async (request) => {
    return teams.api
      .delete(request)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `Team "${request.name}" deleted!`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to delete "${request.name}" team! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        return uiStore.addNotification("error", `Unable to delete "${request.name}" team! Please try again`);
      });
  };

  /**
   * This method sends a request to remove a user from a team.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   *
   * Request object contains teamId & userId
   * @param  request
   *
   * teamName param is the name of the team user is being removed from.
   * @param  teamName
   *
   * userName param is the name of the user being removed from the team.
   * @param userName
   * @returns true/false if request succeeded/failed
   */
  removeUserFromTeam = async (teamName, userName, request) => {
    return teams.api
      .removeUserFromTeam(request)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `"${userName}" successfully removed from "${teamName}" team`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to remove "${userName}" from "${teamName}"! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        return uiStore.addNotification("error", `Unable to remove "${userName}" from "${teamName}"! Please try again`);
      });
  };

  /**
   * This method sends a request to update a teams details
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   *
   * Request object contains updated team details
   * @param  request
   *
   * toasts param are the for the success/error messages to show for the form field being updated.
   * You can only edit/updated one form field at a time so we need to track this and display relevant
   * toast messages.
   * @param  toasts
   *
   * @returns true/false if request succeeded/failed
   */
  update = async (request, toasts) => {
    const { success, error } = toasts;
    return teams.api
      .update(request)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `${success}`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `${error}`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `${error}`);
        return { data: "", response: false };
      });
  };

  /**
   * This method sends a request to add members to a team api. The request object
   * contains teamId, users and roles.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   * @param  request
   * @returns true/false if request succeeded/failed
   */
  addUsersToTeam = async (request) => {
    const { teamId } = request;
    const { name } = this.getTeamById(teamId);

    return teams.api
      .addUsersToTeam(request)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `Members successfully added to "${name}" team`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `"${response.data[0]}"`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        return uiStore.addNotification("error", `Unable to add members to team! Please try again`);
      });
  };
}
export { TeamsStore };
